import React from 'react'
import styled from 'styled-components'

// const get = (key, defaultValue) => (obj = {}) => obj[key] || defaultValue

export const Section = styled.section`
  flex: 0 0 100%;
  display: block;
  // flex-direction: ${props => props.direction || 'row'};
  // flex-wrap: wrap;
  // align-items: ${props => props.align || 'inherit'};
  // justify-content: ${props => props.justify || 'center'};

  padding: 130px 0;
  //
  //max-width: 100%;
  //min-width: 100%;

  @media (max-width: 600px) {
    padding: 100px 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  max-width: ${props => (props.wide ? '1200px' : '1000px')};
  padding: 1rem;
  flex: 0 0 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    //padding: 0.5rem;
    //flex-basis: 100%;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: ${props => props.wrap || 'inherit'};
  align-items: ${props => props.align || 'inherit'};
  justify-content: ${props => props.justify || 'center'};

  padding: ${({ pad = true }) => (pad ? '1rem' : '0')};

  width: ${props => props.width || 'inherit'};
  max-width: ${props => props.maxWidth || 'inherit'};

  @media (max-width: 600px) {
    padding: ${({ pad = true }) => (pad ? '0.5rem' : '0')};
    //flex-basis: 100%
  }
`

export const Col = styled.div`
  flex: 1;
  padding: ${({ pad = true }) => (pad ? '1rem' : '0')};
  align-items: ${props => props.align || 'inherit'};
  justify-content: ${props => props.justify || 'center'};

  @media (max-width: 600px) {
    padding: ${({ pad = true }) => (pad ? '0.5rem' : '0')};
    //flex-basis: 100%
  }
`

export class Responsive extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      size: {
        width: 0,
        height: 0,
      },
    }
  }

  handleResize = () =>
    this.setState({
      size: {
        width: window.outerWidth,
        height: window.outerHeight,
      },
    })

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.handler = window.addEventListener('resize', this.handleResize)
      this.handleResize()
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  render() {
    return this.props.children(this.state.size)
  }
}
