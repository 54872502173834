import React from 'react'

import styled from 'styled-components'


export const Heading = styled.h1`
  display: ${props => props.inline ? 'inline' : 'block' };
  font-family: 'Poppins', 'Montserrat', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  font-size: ${props => props.large ? '3.2rem' : '2.4rem'};
  color: #343434;
  //font-weight: 500;
  text-align: ${({ align = 'left' } = {}) => align};
  
  min-width: 10rem;
  
  @media (max-width: 800px) {
    font-size: ${props => props.large ? '2.6rem' : '2rem'};
  }
  
  @media (max-width: 500px) {
    font-size: ${props => props.large ? '2.3rem' : '1.8rem'};
  }
 `

export const H1 = Heading

export const H2 = styled.h2`
  display: ${props => props.inline ? 'inline' : 'block' };
  font-family: 'Poppins', 'Montserrat', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  font-size: 1.55rem;
  color: #343434;
  
  min-width: 10rem;
  text-align: ${({ align = 'left' } = {}) => align};
 `

export const H3 = styled.h3`
  display: ${props => props.inline ? 'inline' : 'block' };
  font-family: 'Poppins', 'Montserrat', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  font-size: 1.2rem;
  color: #343434;
  min-width: 10rem;
  text-align: ${({ align = 'left' } = {}) => align};
 `

export const Subtitle = styled.h4`
  display: ${props => props.inline ? 'inline' : 'block' };
  font-family: 'Poppins', 'Montserrat', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  font-size: 1.05rem;
  font-weight: 500;
  color: #6e7377;
  min-width: 10rem;
  text-align: ${({ align = 'left' } = {}) => align};
 `
export const H4 = Subtitle

export const Paragraph = styled.p`
  display: ${props => props.inline ? 'inline' : 'block' };
  font-family: 'Source Sans Pro','Helvetica Neue', 'Helvetica', Arial, sans-serif;
  font-size: ${props => props.large? '1.3rem' : '1rem'};
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
  line-height: 1.5rem;
  max-width: ${({ narrow }) => narrow ? '25rem' : '50rem'};
  min-width: 10rem;
  color: #767676;
  margin-top: .5rem;
  margin-bottom: 1rem;
  text-align: ${({ align = 'left' } = {}) => align};
 `

export const P = Paragraph


export const FinePrint = styled.p`
  display: ${props => props.inline ? 'inline' : 'block' };
  font-family: 'Source Sans Pro','Helvetica Neue', 'Helvetica', Arial, sans-serif;
  font-size: .7rem;
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
  line-height: 1.4rem;
  max-width: ${({ narrow }) => narrow ? '25rem' : '50rem'};
  color: ${({ inverted }) => inverted ? '#f5f5f5' : '#767676'} ;
  margin-top: .2rem;
  margin-bottom: .2rem;
  text-align: ${({ align = 'left' } = {}) => align};
 `


export const Quote = styled.h4`
  display: ${props => props.inline ? 'inline' : 'block' };
  border-left: 3px solid #343434;
  padding-left: 1rem;
  font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  font-style: italic;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5rem;
  max-width: 30rem;
  color: #343434;
  text-align: ${({ align = 'left' } = {}) => align};
 `
