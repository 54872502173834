import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { FinePrint, H3, Paragraph } from '../components/base/Typography'

import { List, ListItem } from './containers/List'
import { Header, HeaderImage, HeaderMenu } from './containers/Header'
import '../css/reset.css'
import '../css/base.css'
import '../css/flex.css'
import logo from '../media/bite_ai_logo.png'
import logoWhite from '../media/bite-ai-logo-white-font.svg'
import { Link } from 'gatsby'
import { NewsletterSubscribeForm } from '../components/NewsletterSubscribeForm'

const Nav = styled.div`
  position: relative;
  margin: 2px;
  padding: 5px;
  z-index: 100;

  .nav-dropdown:hover .dropdown {
    display: inherit;
    //visibility: visible;
  }

  .nav-dropdown .dropdown {
    display: none;
    //visibility: hidden;
  }
`

const NavDropdown = ({ children }) => (
  <div
    className={'nav-dropdown'}
    style={{ position: 'relative', display: 'inline-block' }}>
    {children}
  </div>
)

export const SiteHeader = () => (
  <Header>
    <Link to="/">
      <HeaderImage src={logo} alt={'Bite AI Logo'} />
    </Link>
    <HeaderMenu>
      {/*<Nav style={{ position: 'relative' }}>*/}
      {/*  <Link to="/" style={{}}>*/}
      {/*    <p style={{ padding: '0 8px' }}>Use Cases</p>*/}
      {/*  </Link>*/}
      {/*  <div*/}
      {/*    className={'nav-dropdown'}*/}
      {/*    style={{*/}
      {/*      position: 'absolute',*/}
      {/*      top: '100%',*/}
      {/*      right: -40,*/}
      {/*      padding: 15,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div*/}
      {/*      style={{*/}
      {/*        minWidth: 300,*/}
      {/*        maxWidth: '100%',*/}
      {/*        border: '1px solid rgba(0,0,0,.04)',*/}
      {/*        backgroundColor: 'white',*/}
      {/*        boxShadow: 'rgba(0, 0, 0, .1) 0px 40px 100px -10px',*/}
      {/*        borderRadius: 5,*/}
      {/*        padding: 40,*/}
      {/*        // marginTop: 20,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      /!*<div style={{ padding: 10 }}>Retail</div>*!/*/}
      {/*      <div style={{ padding: 10 }}>*/}
      {/*        <Link to="/drafts/nutrition-research/">Nutrition Research</Link>*/}
      {/*      </div>*/}
      {/*      /!*<div style={{ padding: 10 }}>Market Research</div>*!/*/}
      {/*      /!*<div style={{ padding: 10 }}>Healthcare</div>*!/*/}
      {/*      /!*<div style={{ padding: 10 }}>Food Discovery</div>*!/*/}
      {/*      /!*<div style={{ padding: 10 }}>Health & Fitness Apps</div>*!/*/}
      {/*      /!*<div style={{ padding: 10 }}>Smart Appliances</div>*!/*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Nav>*/}
      <Nav className="header-nav" style={{ position: 'relative' }}>
        <NavDropdown>
          <Link to="/" style={{}}>
            <p style={{ padding: '0 10px' }}>Products</p>
          </Link>
          <div
            className={'dropdown'}
            style={{
              position: 'absolute',
              top: '100%',
              right: -120,
              padding: 15,
            }}>
            <div
              style={{
                width: 450,
                border: '1px solid rgba(0,0,0,.04)',
                backgroundColor: 'white',
                boxShadow: 'rgba(0, 0, 0, .1) 0px 40px 100px -10px',
                borderRadius: 8,
                padding: '35px 40px',
                // marginTop: 20,
              }}>

               <Link to="/food-logging-sdk/">
                <div style={{ padding: 10 }}>
                  <H3 style={{ marginBottom: 0 }}>Food Logging</H3>
                  <FinePrint
                    style={{
                      margin: 0,
                      fontSize: '.88rem',
                      lineHeight: '1.2rem',
                    }}>
                    Add automated food logging to your app
                  </FinePrint>
                </div>
              </Link>
              <Link to="/food-recognition/">
                <div style={{ padding: 10 }}>
                  <H3 style={{ marginBottom: 0 }}>Food Recognition</H3>
                  <FinePrint
                    style={{
                      margin: 0,
                      fontSize: '.88rem',
                      lineHeight: '1.2rem',
                    }}>
                    Analyze and organize food images
                  </FinePrint>
                </div>
              </Link>
              {/*<Link to="/drafts/visual-search/">*/}
              {/*  <div style={{ padding: 10 }}>*/}
              {/*    <H3 style={{ marginBottom: 0 }}>*/}
              {/*      Visual Search And Discovery*/}
              {/*    </H3>*/}
              {/*    <FinePrint*/}
              {/*      style={{*/}
              {/*        margin: 0,*/}
              {/*        fontSize: '.88rem',*/}
              {/*        lineHeight: '1.2rem',*/}
              {/*      }}>*/}
              {/*      Improve user experience with semantic similarity and visual*/}
              {/*      search*/}
              {/*    </FinePrint>*/}
              {/*  </div>*/}
              {/*</Link>*/}
              {/*<Link to="/drafts/food-knowledge-graph/">*/}
              {/*<div style={{ padding: 10 }}>*/}
              {/*<H3 style={{ marginBottom: 0 }}>Food Knowledge Graph</H3>*/}
              {/*<FinePrint style={{ margin: 0, fontSize: '.88rem', lineHeight: '1.2rem' }}>*/}
              {/*A food ontology that includes dishes, ingredients, nutrition information and how they're related.*/}
              {/*</FinePrint>*/}
              {/*</div>*/}
              {/*</Link>*/}
              {/*<div style={{ padding: 10 }}>Product Digitization</div>*/}
              {/*<div style={{ padding: 10 }}>Food Knowledge Graph</div>*/}
              {/*<div style={{ padding: 10 }}>Natural Language Processing</div>*/}
              {/*<div style={{ padding: 10 }}>OCR</div>*/}
              {/*<div style={{ padding: 10 }}>Meal Planning</div>*/}
              {/*<div style={{ padding: 10 }}>Recommendations</div>*/}
            </div>
          </div>
        </NavDropdown>

        {/*</Link>*/}
        {/*<Link to="/blog/" style={{ margin: 2, padding: 5 }}>*/}
        {/*  <p style={{ padding: '0 8px' }}>Blog</p>*/}
        {/*</Link>*/}
        {/* <a href="https://docs.bite.ai" style={{ margin: 2, padding: 5 }}>
          <p style={{ padding: '0 10px' }}>Docs</p>
        </a> */}
        {/*<Link to="/drafts/pricing/" style={{ margin: 2, padding: 5 }}>*/}
        {/*  <p style={{ padding: '0 10px' }}>Pricing</p>*/}
        {/*</Link>*/}
        <a href="https://developer.bite.ai" style={{ margin: 2, padding: 5 }}>
          <p style={{ padding: '0 10px' }}>Sign In</p>
        </a>
      </Nav>
    </HeaderMenu>
  </Header>
)

let StyledNewsletterSubscribeForm = styled(NewsletterSubscribeForm)`
  & {
    padding: 0;
  }

  p {
    font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', Arial,
      sans-serif;
    font-size: 0.65rem;
  }
  .container {
    max-width: 200px;
    padding: 0;
  }
  .emailInput {
    font-size: 0.65rem;
  }
`

export const Footer = () => (
  <footer
    className={'flex-row flex-center'}
    style={{
      backgroundColor: '#232323',
      padding: '100px 0',

      // display: 'flex',
      // flex: 1,
    }}>
    <div className={'flex-stretch'} style={{ maxWidth: 1000 }}>
      <div className={'flex-stretch flex-row flex-around flex-wrap'}>
        <div>
          <List>
            <ListItem>
              <img
                src={logoWhite}
                height={30}
                style={{ marginBottom: 10 }}
                alt="Bite AI Logo"
              />
            </ListItem>
          </List>
        </div>
        <div>
          <List>
            <ListItem style={{ marginBottom: 20 }}>
              <H3>NAVIGATE</H3>
            </ListItem>
            <ListItem>
              <a href={'https://getbitesnap.com'}>Bitesnap</a>
            </ListItem>
            <ListItem>
              <Link to={'/terms'} href={'https://getbitesnap.com'}>
                Terms of Service
              </Link>
            </ListItem>
            <ListItem>
              <Link to={'/privacy'} href={'https://getbitesnap.com'}>
                Privacy Policy
              </Link>
            </ListItem>
          </List>
        </div>
        <div>
          <List>
            <ListItem style={{ marginBottom: 20 }}>
              <H3>Subscribe</H3>
            </ListItem>
            <ListItem>
              <StyledNewsletterSubscribeForm />
            </ListItem>
          </List>
        </div>
      </div>
      <div className={'flex-stretch flex-row flex-center'}>
        <FinePrint align={'center'} style={{ padding: '30px 0' }}>
          © Copyright {new Date().getFullYear()} Bite AI, inc - All Rights
          Reserved{' '}
        </FinePrint>
      </div>
    </div>
  </footer>
)

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: 'Bite AI - Food Intelligence Platform',
            },
            {
              property: 'og:description',
              content: 'Bite AI - Food Intelligence Platform',
            },
            {
              name: 'keywords',
              content:
                'food recognition, api, deep learning, image recognition,' +
                'food recognition api, food search, food image recognition,' +
                ' food knowledge graph',
            },
          ]}
        />
        <SiteHeader />
        {children}
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
