import React from 'react'

export class Header extends React.Component {
  render() {
    return (
      <header
        style={{
          // border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}>
        <div style={{
          maxWidth: 1000,
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          padding: '1rem 1rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
        {this.props.children}
        </div>
      </header>
    )
  }
}


export class HeaderImage extends React.Component {
  render() {
    return (
      <img src={this.props.src} height={55} style={{padding: 5}} alt={this.props.alt}/>
    )
  }
}

export class HeaderMenu extends React.Component {
  render() {
    return <nav style={{display: 'flex', flexDirection: 'row'}}>{this.props.children}</nav>
  }
}
