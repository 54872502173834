import React from 'react'

import { StaticQuery, graphql } from 'gatsby'

import { Row, Col } from '../components/containers/Layout'
import { TextInput } from '../components/base/Forms'
import { Heading, Paragraph } from '../components/base/Typography'

import Button from '../components/base/Button'

const STATUS = {
  NONE: null,
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
}

export class NewsletterSubscribeForm extends React.Component {
  static defaultProps = {
    requiredFields: ['email'],
    buttonText: 'Go',
    text: '',
  }

  constructor(props) {
    super(props)

    this.state = {
      status: STATUS.NONE,
      validationMessage: '',
      touched: {},
      errors: {},
    }

    this.ref = {
      email: React.createRef(),
    }
  }

  get values() {
    return {
      email: this.ref.email.current.value || '',
    }
  }

  submit = async (e, url) => {
    console.log(url)
    console.log('HELLO')
    e.preventDefault()
    if (!this.validate()) {
      this.setState({
        status: STATUS.NONE,
      })
      return
    }

    this.setState({
      status: STATUS.PENDING,
      validationMessage: 'loading...',
    })

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.values),
    })

    if (response.status >= 400) {
      this.setState({
        status: STATUS.ERROR,
        validationMessage: JSON.stringify(content),
      })
      //"We couldn't send your request, you can try reaching us at info@bite.ai"
      return
    }

    const content = await response.json()

    if (content.status === 'ERROR') {
      console.log(content)
      this.setState({
        status: STATUS.ERROR,
        validationMessage: JSON.stringify(content),
      })
      //+  " We couldn't send your request, you can try reaching us at info@bite.ai"
    } else {
      this.setState({
        status: STATUS.SUCCESS,
        validationMessage: 'Thank you for signing up!',
      })
    }
  }

  updateInput = e => {
    this.setState({ inputs: { [e.target.name]: e.target.value } })
  }

  handleInteraction = e => {
    const touched = { ...this.state.touched, [e.target.name]: true }
    this.setState({ touched })
    this.validate(Object.keys(touched))
  }

  validate = (fields = Object.keys(this.ref)) => {
    const missingFields = fields.filter(
      field =>
        this.props.requiredFields.includes(field) &&
        !this.ref[field].current.value
    )
    if (missingFields.length) {
      this.setState({
        errors: missingFields.reduce((x, field) => {
          x[field] = true
          return x
        }, {}),
        validationMessage: `Please include your ${missingFields.join(', ')}`,
      })
      return false
    }

    this.setState({
      errors: {},
      validationMessage: '',
    })
    return true
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query formQuery {
            site {
              siteMetadata {
                newsletterFormURL
              }
            }
          }
        `}
        render={data => (
          <form
            action={data.site.siteMetadata.newsletterFormURL}
            method={'post'}
            onSubmit={e => {
              this.submit(e, data.site.siteMetadata.newsletterFormURL)
            }}
            noValidate={true}
            className={this.props.className}>
            <Col className="container">
              <Paragraph className="text">{this.props.text}</Paragraph>
              <div style={{display: 'flex'}}>
                <TextInput
                  className={'emailInput'}
                  required
                  error={this.state.errors.email}
                  ref={this.ref.email}
                  name={'email'}
                  placeholder={'email'}
                  autoComplete={'email'}
                  onBlur={this.handleInteraction}
                  onChange={this.handleInteraction}
                  style={{
                    margin: 0,
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 5,
                    borderTopLeftRadius: 5,
                  }}
                />
                <Button
                  size={'small'}
                  type={'submit'}
                  style={{
                    margin: 0,
                    borderBottomRightRadius: 5,
                    borderTopRightRadius: 5,
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                  }}>
                  {this.props.buttonText}
                </Button>
              </div>
              <Row className="errorContainer" style={{ padding: '.1rem' }}>
                {this.state.validationMessage &&
                this.state.validationMessage.length ? (
                  <Paragraph
                    className="errorText"
                    style={{ textAlign: 'center' }}>
                    {this.state.validationMessage}
                  </Paragraph>
                ) : null}
              </Row>
            </Col>
          </form>
        )}
      />
    )
  }
}
