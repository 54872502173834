import React from 'react'
import styled from 'styled-components'

const BASE_STYLE = `
  font-family: 'Poppins', 'Montserrat', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  box-sizing: border-box;
  outline: none;
  font-size: 1.2rem;
  padding: 10px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: border 400ms ease-in-out, padding 400ms ease-in-out, box-shadow 600ms ease-in-out;
  // background-color: #FCFCFC;
  
  &:focus {
    box-shadow: 0 2px 16px rgba(203, 203, 203, .3);
  }
`

const Input = styled.input`
  ${BASE_STYLE}
  
  ${({ error }) => error && 
    'border: 2px solid rgba(210, 210, 210, 1); padding: 9px;'}
`

const TextArea = styled.textarea`
  ${BASE_STYLE}
  
  ${({ error }) => error && 
    'border: 2px solid rgba(210, 210, 210, 1); padding: 9px;'}
`

export class TextInput extends React.Component {
  constructor(props) {
    super(props)

    this.input = React.createRef()
  }

  get value() {
    return this.input.current.value
  }

  render() {
    const {
      label: labelText,
      inline = false,
      multiline = false,
      ...rest
    } = this.props

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: inline ? 'row' : 'column',
          flex: 1
        }}>
        <label
          style={{
            fontFamily:
              "'Poppins', 'Montserrat', 'Helvetica Neue'," +
                " 'Helvetica', Arial, sans-serif",
            fontWeight: 500,
            fontSize: '1.2rem'
          }}>
          {labelText}
        </label>
        {multiline ? <TextArea ref={this.input} {...rest} /> : <Input ref={this.input} type={'text'} {...rest} />}

        {/*{helpText}*/}
      </div>
    )
  }
}
