import styled from 'styled-components'

const sizes = {
  small: '.8rem',
  normal: '1rem',
  large: '1.2rem',
  xl: '1.4rem'
}



const Button = styled.button`
  text-align: center;
  display: inline-block;
  padding: .5rem 1rem;
  margin: 1rem;
  background-color: #565dff;
  border-radius: .5rem;
  color: white;
  font-weight: 600;
  font-size: ${({size = 'normal'} = {}) => sizes[size] || sizes['normal']};
  border: 1px solid transparent;
  transition: background-color 300ms ease-in-out, box-shadow 300ms ease-in-out, transform 300ms ease-in-out;
  
  &:focus {
    outline: 0;
    cursor: pointer;
    //background-color: #4e52e2;
    box-shadow: 0 3px 8px rgba(68,68,68,.2);
    transform: scale(1.01);
  }
  
  &:hover {
    outline: 0;
    cursor: pointer;
    //background-color: #4e52e2;
    box-shadow: 0 3px 8px rgba(68,68,68,.2);
    transform: scale(1.01);
  }
`

export const ButtonLink = styled.a`
  text-align: center;
  display: inline-block;
  padding: .5rem 1rem;
  margin: 1rem;
  background-color: #565dff;
  border-radius: .5rem;
  color: white;
  font-weight: 600;
  font-size: ${({size = 'normal'} = {}) => sizes[size] || sizes['normal']};
  border: 1px solid transparent;
  transition: background-color 300ms ease-in-out, box-shadow 300ms ease-in-out, transform 300ms ease-in-out;
  
  &:focus {
    outline: 0;
    cursor: pointer;
    //background-color: #4e52e2;
    box-shadow: 0 3px 8px rgba(68,68,68,.2);
    transform: scale(1.01);
  }
  
  &:hover {
    outline: 0;
    cursor: pointer;
    //background-color: #4e52e2;
    box-shadow: 0 3px 8px rgba(68,68,68,.2);
    transform: scale(1.01);
  }
`

export default Button
